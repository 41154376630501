.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #bdd8ef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.thumb {
  border: 0;
  cursor: pointer;
}

.rcw-response .rcw-message-text {
  max-width: 800px !important;
}

.rcw-snippet {
  max-width: 800px !important;
}

.rcw-response a {
  border-left: 2px solid #35e65d;
  margin-top: 5px;
  padding-left: 10px;
  display: block;
  text-decoration: none;
}

.rcw-response a:hover {
  text-decoration: underline;
}

.rcw-message ul, ol {
  padding-left: 30px;
}

#messages .rcw-message {
  white-space: normal;
}

#messages .rcw-message-text p {
  margin: 0.5em 0;
}
